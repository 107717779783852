"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchOwnedShoppingLists = void 0;
var _config = require("../../constants/config");
var fetchOwnedShoppingLists = exports.fetchOwnedShoppingLists = function fetchOwnedShoppingLists() {
  return fetch("".concat(_config.BASE_URL, "/shopping-lists"), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'include' // Include cookies in the request
  });
};