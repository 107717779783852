"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "createShareLink", {
  enumerable: true,
  get: function get() {
    return _createShareLink.createShareLink;
  }
});
var _createShareLink = require("./createShareLink");