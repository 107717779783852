"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "deleteShoppingList", {
  enumerable: true,
  get: function get() {
    return _deleteShoppingList.deleteShoppingList;
  }
});
var _deleteShoppingList = require("./deleteShoppingList");