"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BASE_URL", {
  enumerable: true,
  get: function get() {
    return _config.BASE_URL;
  }
});
var _config = require("./config");