"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "fetchGroceryItems", {
  enumerable: true,
  get: function get() {
    return _fetchGroceryItems.fetchGroceryItems;
  }
});
var _fetchGroceryItems = require("./fetchGroceryItems");