"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AuthProvider", {
  enumerable: true,
  get: function get() {
    return _AuthContext.AuthProvider;
  }
});
Object.defineProperty(exports, "useAuth", {
  enumerable: true,
  get: function get() {
    return _AuthContext.useAuth;
  }
});
var _AuthContext = require("./AuthContext.js");