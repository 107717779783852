"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _AuthContext = require("./AuthContext");
Object.keys(_AuthContext).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _AuthContext[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _AuthContext[key];
    }
  });
});