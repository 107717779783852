"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "fetchOwnedShoppingLists", {
  enumerable: true,
  get: function get() {
    return _fetchOwnedShoppingLists.fetchOwnedShoppingLists;
  }
});
var _fetchOwnedShoppingLists = require("./fetchOwnedShoppingLists");